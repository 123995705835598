import React from "react"

import Layout from "../components/layout"
import CommonDedicatedTeam from "../components/dedicated-team/CommonDedicatedTeam"

const DedicatedTeamJava = () => {
  return (
    <Layout pageTitle="Dedicated Java Developers">
      {({ width }) => <CommonDedicatedTeam type="Java" screenWidth={width} />}
    </Layout>
  )
}

export default DedicatedTeamJava
